.main-admin {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.contener-admin {
    box-sizing: border-box;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.contener-admin div h2:hover {
    cursor: pointer;
    color: var(--var-color);
}

.content-admin {
    box-sizing: border-box;
    width: 70%;
}

.name,
.type,
.movietime,
.direction,
.image,
.imgdesc,
.rating,
.movieseancetime,
.movieseancedata {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
}

.desc {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: 100%;
    height: 200px;
}

.save-movie-btn {
    min-width: 100%;
    cursor: pointer;
    margin-right: 0.25em;
    margin-top: 1.5em;
    padding:  0.938em;
    border: none;
    border-radius: 4px;
    background-color: rgb(15, 117, 124);
    color: #fefefe;
  }
  
  .save-movie-btn:hover {
    background-color: rgb(8, 75, 80);
    color: #fefefe;
  }

  .form-wrapper-admin form textarea {
    width: 100%;
    height: 100%;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    /* resize: none; */
    z-index: 2;
}

.image, 
.imgdesc {
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 130px;
    padding: 20px;
    border-radius: 10px;
    border: 2px dashed #555;
    background-color: #eee;
    cursor: pointer;
    transition: background .2s ease-in-out, border .2s ease-in-out;
  }
  
  .image:hover, .imgdesc:hover {
    background: #b0b0b0;
    border-color: #111;
  }
  
  .image:hover .drop-title,
  .imgdesc:hover .drop-title{
    color: #222;
  }
   
  .drop-title {
    color: #444;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    transition: color .2s ease-in-out;
  }

  input[type=file] {
    width: 350px;
    max-width: 100%;
    color: #444;
    padding: 5px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #555;
  }
  
  input[type=file]::file-selector-button {
    margin-right: 20px;
    border: none;
    background: rgb(15, 117, 124);
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background .2s ease-in-out;
  }
  
  input[type=file]::file-selector-button:hover {
    background: rgb(8, 75, 80);
  }

  .search_ul_seance {
    list-style-type: none;
  }

  .movie_search li{
    margin: 5px;
  }

  .movie_search li:hover{
    color: var(--var-color);
    cursor: pointer;
  }