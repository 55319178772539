a {
    text-decoration: none;
}
.repertoire-container {
    display: flex;
    position:relative;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 100%;
    font-size: 2em;
    color: white;
    border-bottom: 1px solid gray;
}

.week {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 70%;
    margin: 20px;
    height: auto;
    box-sizing: border-box;
}

.week div:hover {
    cursor: pointer;
    color: rgb(0, 238, 255);
}

.today {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.repertoire-films {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.repertoire-simple-film {
    border-bottom: 1px solid grey;
    padding-top: 2.5%;
    padding-bottom: 2.5%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
}

.movie-repertoire-info {
    padding-left: 5%;
    color: white;
    box-sizing: border-box;
}

.seance-time-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    height: auto;
    width: 100%;
}

.seance-time {
    display: flex;
    flex-grow: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100px;
    background-color: #182131;
    margin: 10px;
    border-radius: 15px;
}

.seance-time-link {
    text-decoration: none;

}
.seance-time-link:hover {
    color: rgb(0, 238, 255);
}

@media screen and (max-width: 500px) {
    .repertoire-simple-film {
        flex-direction: column;
        align-items: center;
    }
}