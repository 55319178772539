.favorites-films{
    width: 100%;
    height: 350px;
    display: flex;
    padding: 5px;
    box-sizing: border-box;
    z-index: 1;
}

.films{    
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
}

.films div img{
    border-radius: 5%;
    opacity: 0.75;
    width: 100%;
    height: 320px;
    cursor: pointer;
}

.films div img:hover{
    opacity: 1;
    box-shadow: 0px 0px 5px white
}


.films div{
    display: flex;
    justify-content: center;
    height: 100%;
    flex-grow: 1;
    margin: 5px;
    box-sizing: border-box;
    flex-wrap: wrap;
}

.information-all-film{
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    justify-content: space-between;
    height: 50px;
}

.aside-button-left img {
    height: 20px;
    cursor: pointer;
}

.aside-button-right img {
    height: 20px;
    cursor: pointer;
}

.all-film-title{
    color: white;
    font-size: 20px;
}

.favorites-film-title{
    color: white;
    font-size: 20px;
}

.asc-search-title {
    color: white;
    font-size: 20px;
}