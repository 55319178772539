
.contener-for-booking {
    color: white;
    width: 100%;
    padding-top: 2%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.screen-container {
    perspective: 1000px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.screen {
  background: rgb(255, 255, 255);
  height: 70px;
  width: 600px;
  margin: 25px 0;
  transform: rotateX(-45deg);
  box-shadow: 0 3px 10px rgba(255,255,255,0.7);
}

.seat-container {
    width: 100%;
}

.armchairs-container {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 500px;
    height: auto;
}

.simple-chair {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 10%;
    margin: 2px;
    border-radius: 10px;
    color: rgb(0, 0, 0);
    background-color: rgb(212, 212, 212);
}

.hidden {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 10%;
    margin: 2px;
    border-radius: 10px;
}

.reserved-chair {
    cursor: context-menu;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 0, 0);
}

.selected-chair {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 10%;
    margin: 2px;
    border-radius: 10px;
    color: rgb(255, 255, 255);
    background-color: rgb(56, 56, 56);
}

.simple-chair:hover {
    color: rgb(212, 212, 212);
    background-color: rgb(0, 149, 160);
}

.reserved-chair:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(0, 0, 0);
}

.seat-container-title {
    display: flex;
    justify-content: center;
    width: 100%;

}

.seat-container-all-info {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: row;
}

.seat-container-all-info p {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

  .information {
    display: flex;
    flex-direction: row;
    width: 70%;
    align-items: center;
    justify-content: space-between;
    margin: 30px;
    flex-wrap: wrap;
    box-sizing: border-box;
  }

  .information-button-simple {
    margin: 2px;
    border-radius: 10px;
    color: rgb(0, 0, 0);
    background-color: rgb(212, 212, 212);
  }

  .information-button-selected {
    margin: 2px;
    border-radius: 10px;
    color: rgb(255, 255, 255);
    background-color: rgb(56, 56, 56);
  }

  .information-button-reserved {
    margin: 2px;
    border-radius: 10px;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 0, 0);
  }

  .reserve {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin: 1%;
    box-sizing: border-box;
  }

  .reserve-button {
    cursor: pointer;
    margin-bottom: 50px;
    border-radius: 10px;
    color: rgb(0, 0, 0);
    background-color: rgb(212, 212, 212);
    border: 0;
  }

  .reserve-button:hover {
    color: rgb(212, 212, 212);
    background-color: rgb(0, 98, 105);
}


@media screen and (max-width: 500px) {
  .armchairs-container {
    width: 400px;
  }
}
