.contener{
    width: 100%;
    padding-bottom: 20px;
    height: 400px;
    display: flex;
}

.img-contener{
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}

.title-baner {
    color: white;
    text-shadow: 0px 0px 10px rgb(0, 0, 0);
    margin-left: 3%;
    margin-top: 1%;
    box-sizing: border-box;
}

.more-info-baner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    box-sizing: border-box;
    width: 100%;
    color: white;
    text-shadow: 0px 0px 10px rgb(0, 0, 0);
}