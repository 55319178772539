.movie-details-contener {
    color: whitesmoke;
    position:relative;
    width: 100%;
    height: 500px;
    max-width: 100%;
    overflow: hidden;
}

.imagedesc {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.movie-details-flex-contener {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 3;
    width: 100%;
    height: 100%;
}

.image-movie-details {
    width: 30%;
}

.movie-details-desc {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50%;
    text-shadow: 0px 0px 15px rgb(0, 0, 0);
}

.film-info {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    text-shadow: 0px 0px 15px rgb(0, 0, 0);
}

.comment-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin-top: 2%;
    color: white;
}

.user-comment {
    width: 100%;
    padding-top: 5px;
    padding-left: 40px;
    padding-bottom: 20px;
    margin-bottom: 1.5%;
    box-sizing: border-box;
    border-radius: 20px;
    background-color: #182131;
}

.username-and-edit {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.delete-comment {
    height: 20px;
    z-index: 5;
    width: auto;
}

.delete-comment img {
    margin-right: 20px;
    box-sizing: border-box;
    height: 20px;
    width: auto;
    cursor: pointer;
}

.add-comment {
    width: 100%;
}

.add-comment form textarea {
    width: 100%;
    height: 100px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
    z-index: 2;
}

.add-comment form {
    width: 100%;
}

.add-comment h4 {
    justify-content: center;
    display: flex;
}

.delete-comment {
    position: relative;
    right: 0;
}

.movie-details-link {
    text-decoration: none;
    color: white;
}

.movie-details-link:hover {
    color: grey;
}

.button{
    width: 100%;
    right: 0;
}

.button-submit-comment {
    width: 20%;
    cursor: pointer;
    padding:  0.938em;
    border: none;
    border-radius: 4px;
    background-color: rgb(15, 117, 124);
    color: #fefefe;
    position: relative;
    right: -80%;
    bottom: 100%;
    z-index: 5;
}
  
.button-submit-comment:hover {
    background-color: rgb(8, 75, 80);
    color: #fefefe;
}

.react-confirm-alert {
    margin-left: 35%;
}

@media screen and (max-width: 700px) {
    .image-movie-details{
        display: none;
    }

    .movie-details-desc {
        width: 100%;
        padding: 10px;
    }
}

