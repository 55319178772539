p, div {
    font-family: Lato;
  }
    
  .wrapper {
    /* height: 70vh; */
    width: 100%;
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #020916;
  }

  .wrapper-login {
    width: 100%;
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #020916;
  }
  
  .form-wrapper-register {
    display: flex;
    flex-direction: column;
    width: 250px;
    padding: 20px 40px;
    border-radius: 6px;
    box-shadow: 0px 0px 8px rgb(48, 48, 48);
    background-color: #182131;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    width: 250px;
    padding: 20px 40px;
    border-radius: 6px;
    box-shadow: 0px 0px 8px rgb(48, 48, 48);
    background-color: #182131;
  }
  
  .form-wrapper > h2 {
    display: flex;
    justify-content: center;
    font-family: "Segoe UI", "Ubuntu", "Roboto", "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 2em;
    font-weight: lighter;
    margin-top: 0.25em;
    color: #fff;
  }

  .form-wrapper-register > h2 {
    display: flex;
    justify-content: center;
    font-family: "Segoe UI", "Ubuntu", "Roboto", "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 2em;
    font-weight: lighter;
    margin-top: 0.25em;
    color: #fff;
  }
  
  form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }  
  
  label {
    margin-bottom: 0.5em;
    color: #fff;
    font-weight: lighter;
  }
  
  input {
    padding: 10px 10px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #d6d1d5;
  }

  input::placeholder {
    font-size: 1.2em;
    font-weight: lighter;
    color: #bbb;
  }
  
  .sign-in-up {
    min-width: 100%;
    cursor: pointer;
    margin-right: 0.25em;
    margin-top: 1.5em;
    padding:  0.938em;
    border: none;
    border-radius: 4px;
    background-color: rgb(15, 117, 124);
    color: #fefefe;
  }
  
  .sign-in-up:hover {
    background-color: rgb(8, 75, 80);
    color: #fefefe;
  }
   
  .username,
  .first-name,
  .last-name,
  .email,
  .password {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: 100%;
  }
   
  .submit {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    box-sizing: border-box;
  }