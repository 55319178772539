body {
    margin: 0 auto;
    --var-color: rgb(0, 238, 255);
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

a {
    color: white;
    text-decoration: none;
}

.header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #182131;
    padding: 20px;
    box-sizing: border-box;
    z-index: 100;
}

.sign{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 15px;
}

.sign div {
    margin-left: 15px;
    box-sizing: border-box;
}

.search_input {
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transition: transform 250ms ease-in-out;
    font-size: 14px;
    line-height: 18px;
    color: #575756;
    background-color: transparent;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 18px 18px;
    background-position: 95% center;
    border-radius: 50px;
    border: 1px solid #575756;
    transition: all 250ms ease-in-out;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    box-sizing: border-box;
    z-index: 5;
}

.login {
    font-size: 1rem;
    color: #FFF;
    padding: 5px;
    box-sizing: border-box;
}

.login a:hover {
    color: var(--var-color);
}

.register{
    font-size: 1rem;
    color: #FFF;
    padding: 5px;
    box-sizing: border-box;
}

.register a:hover {
    color: var(--var-color);
}

.repertoire-link {
    font-size: 1rem;
    color: #FFF;
    padding: 5px;
    box-sizing: border-box;
}

.admin-panel {
    font-size: 1rem;
    color: #FFF;
    padding: 2%;
    box-sizing: border-box;
}

.admin-panel a:hover {
    color: var(--var-color);
}

.repertoire-link a:hover {
    color: var(--var-color);
}

.logo {
    width: 10%;
    font-size: 1.9rem;
    color: var(--var-color);
    font-weight: bold;
    box-sizing: border-box;
}

.logo::before {
    position: absolute;
    width: 10%;
    content: attr(data-text);
    text-shadow: 0px 0px 20px var(--var-color);
    filter: blur(10px), brightness(0);
    animation: flicker 5s linear forwards;
    animation-delay: 2s;
}

@keyframes flicker {
    0% {
        filter: blur(5px) brightness(1);
    }
    3% {
        filter: blur(5px) brightness(0);
    }
    6% {
        filter: blur(5px) brightness(0);
    }
    7% {
        filter: blur(5px) brightness(1);
    }
    8% {
        filter: blur(5px) brightness(0);
    }
    9% {
        filter: blur(5px) brightness(1);
    }
    10% {
        filter: blur(5px) brightness(0);
    }
    20% {
        filter: blur(5px) brightness(1);
    }
    50% {
        filter: blur(5px) brightness(1);
    }
    99% {
        filter: blur(5px) brightness(0);
    }
    100% {
        filter: blur(5px) brightness(1);
    }
}

.search_ul {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.search_ul ul {
    list-style-type: none;
}

.movie_link {
    text-decoration: none;
}

.search_li {
    display: flex;
    justify-content: center;
    padding: 15px;
    box-sizing: border-box;
    width: 100%;
}

.search_li:hover {
    color: var(--var-color);
}

.search::placeholder {
    color: color(#575756 a(0.8));
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.search_input:hover{
    padding: 12px 0;
    outline: 0;
    border: 1px solid transparent;
    border-bottom: 1px solid #575756;
    border-radius: 0;
    background-position: 100% center;
}

.search_input:focus {
    padding: 12px 0;
    outline: 0;
    border: 1px solid transparent;
    border-bottom: 1px solid #575756;
    border-radius: 0;
    background-position: 100% center;
}